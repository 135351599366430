import React from "react";
import { dice } from "../assets";
import Categories from "./Categories";

const Banner = () => {
  return (
    <div className="mt-[2.5rem] w-full flex-col gap-8  items-center flex ">
      <div className="text-center">
        <h1 className="text-white font-bold text-[2.4rem]  md:text-[4rem]   tracking-wide">
          Explore All The{" "}
          <span className="text-blue-500">Ton Apps You Love</span>
        </h1>
        <p className="text-gray-400 text-base md:text-xl">
          Your one stop to Ton app MarketPlace
        </p>
      </div>

      {/* scrollable list of categories  */}

      <Categories />
    </div>
  );
};

export default Banner;
