import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getlisitngById, getlistingByCategory } from "../schemas/Queries";
import { fetchData } from "../api/requests";
import Listing from "../components/Listing";
import { FaArrowRight } from "react-icons/fa";

const AppDetailsPage = () => {
  const { id } = useParams();

  const [AppDetail, setAppDetail] = useState();
  const [relatedapp, setRelatedapp] = useState([]);
  const [loading, setLoading] = useState(false);
  const pageRef = useRef();

  useEffect(() => {
    pageRef.current.scrollIntoView();
    const getData = async () => {
      setLoading(true);
      const response = await fetchData(getlisitngById(id));
      setAppDetail(response?.data.listings[0]);
      const relatedapps = await fetchData(
        getlistingByCategory(
          `${response?.data.listings[0].categories[0].title}`
        )
      );

      setRelatedapp(relatedapps?.data.listings.filter((item) => item.id != id));

      setLoading(false);
    };

    getData();
  }, [id]);

  return (
    <div ref={pageRef} className="mt-[3rem] w-full flex flex-col">
      {/* Header */}
      <div className="flex flex-col gap-3 mt-[2rem]">
        <div className="flex items-center justify-between ">
          <div className="flex  gap-3 ">
            <div className=" h-[150px] w-[150px] rounded-xl">
              {" "}
              <img className="rounded-xl" src={AppDetail?.thumbnailUrl} />
            </div>
            <div>
              <h1 className="font-bold text-white text-xl">
                {AppDetail?.title}
              </h1>
              <p className="text-gray-400">{AppDetail?.categories[0]?.title}</p>
            </div>
          </div>
          <Link
            className="bg-blue-400 px-2  md:px-4 py-2 rounded-xl text-white font-bold"
            to={AppDetail?.listingurl}
          >
            <p className="hidden md:block">Open App</p>
            <FaArrowRight className="block md:hidden" />
          </Link>
        </div>
        <div className="w-full h-[1px] bg-gray-400" />
      </div>

      {/* Suggested Apps */}

      <div className="flex flex-col gap-8 mt-[3rem]">
        <div>
          <h1 className="text-white text-2xl   font-bold">Related Apps</h1>
          <p className="text-gray-400 capitalize">
            See Apps Related To {AppDetail?.categories[0].title}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {relatedapp?.map((item, i) => (
            <Listing app={item} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppDetailsPage;
