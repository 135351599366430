export const categoryquery = `query Categories {
    categories {
      id
      title
      description
    }
  }`;

export const listingquery = `query {
    listings{
      title
      listingurl
      categories {
        id
        title
      }
      thumbnailUrl
    }
  }
  }`;

export const getlistingByCategory = (title) => {
  return `query {
      listings(where: { categories_some: { title: "${title}" } }, first:10) {
        id
        title
        thumbnailUrl
        listingurl
      } 
    }`;
};

export const getlisitngByCategoryId = (id) => {
  return `query {
      listings(where: { categories_some: { id: "${id}" } }) {
        id
        title
        thumbnailUrl
        listingurl

        categories{
          title
          description
        }
      } 
    }`;
};

export const getlisitngById = (id) => {
  return `query {
      listings(where: { id: "${id}" } ) {
        id
        title
        thumbnailUrl
        listingurl

        categories{
          title
        }
      } 
    }`;
};

export const searchQuery = (query) => {
  return `query {
    listings(where: { 
      OR: [
        { title_contains: "${query}" },
        { categories_some: { title: "${query}" } }
      ]
     }){
        id
        title
        thumbnailUrl
        listingurl

        categories{
          title
          description
        }
      } 
    }`;
};
