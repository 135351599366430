import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const HandleKeyDown = (e) => {
    if (!e.target.value) {
      return;
    }
    if (e.key === "Enter") {
      console.log("hello enter");
      navigate(`/search/${e.target.value}`);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="logo">
        <Link to={"/"} className="text-white font-extrabold  text-2xl">
          Ton <span className="text-blue-500">Dir</span>
        </Link>
      </div>
      <div className="w-[50%] md:w-[30%]">
        <input
          className="bg-transparent outline-none border-b border-gray-400 w-full text-gray-600"
          placeholder="Press Enter to Search.........."
          type="text"
          onKeyDown={(e) => HandleKeyDown(e)}
        />
      </div>
    </div>
  );
};

export default Navbar;
