import React from 'react'

const Footer = () => {
  // TODO: BUILD FOOTER
  return (
    <div className='h-[10px]'>
      
    </div>
  )
}

export default Footer
