import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";
import { getlistingByCategory } from "../schemas/Queries";
import { fetchData } from "../api/requests";
import Listing from "./Listing";

const Categorylisting = ({ title, description, id }) => {
  const [Apps, setApps] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDataWithRetry = async () => {
      setError(null);
      const query = getlistingByCategory(title);
      try {
        const data = await fetchData(query);
        setApps(data.data.listings);
        console.log(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError(error);
      }
    };
    fetchDataWithRetry();
  }, [title]);

  return (
    <div className="flex flex-col gap-3 min-h-[30vh]">
      <div className="flex justify-between items-start">
        <div className="flex flex-col flex-1">
          <h1 className="font-bold text-2xl md:text-3xl text-white">{title}</h1>
          <p className="text-gray-400 text-ellipsis ">
            {description.substring(0, 52)}...
          </p>
        </div>
        <Link
          className="md:border-blue-500 rounded-full text-blue-400 md:text-gray-600 h-fit md:px-6 py-2 md:border-[1px] hover:scale-105"
          to={`/categories/${id}`}
        >
          See All
        </Link>
      </div>
      {/* listings  */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {Apps.map((app, i) => (
          <Listing app={app} key={i} />
        ))}
      </div>
      {/* Display error message if request fails */}
      {error && (
        <p className="text-white font-bold text-center">
          Error fetching data. Please try again later.
        </p>
      )}
    </div>
  );
};

export default Categorylisting;
