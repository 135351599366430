import React, { useEffect, useState } from "react";
import { MdAddHome } from "react-icons/md";
import { GrGamepad } from "react-icons/gr";
import { FaTools } from "react-icons/fa";
import { GiRadarCrossSection } from "react-icons/gi";
import { fetchData } from "../api/requests";
import { SiGoogleanalytics } from "react-icons/si";
import { categoryquery } from "../schemas/Queries";
import { Link } from "react-router-dom";

const IconsArray = [
  <GrGamepad />,
  <SiGoogleanalytics />,
  <MdAddHome />,
  <FaTools />,
  <GiRadarCrossSection />,
];

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchData(categoryquery);
        setCategories(data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        // Add user feedback for failed data fetching
      }
    };

    getData();
  }, []);

  return (
    <div className="w-full flex flex-col items-center mt-[.6rem] md:mt-[4rem] gap-4">
      {categories.length > 0 && (
        <h3 className="text-gray-400 w-[70%] font-bold text-2xl ">
          Categories
        </h3>
      )}

      <div
        className="w-full md:w-[80%] gap-5  
  flex items-center md:px-3 justify-around py-2 overflow-x-scroll overflow-y-hidden  categories-scrollbar"
      >
        {categories &&
          categories.categories &&
          categories.categories.map((item, i) => (
            <Link
              className="rounded-2xl px-3 py-2  flex-1 bg-blue-500 text-white flex justify-center items-center min-w-[150px]"
              to={`/categories/${item.id}`}
              key={item.id} // Use a unique identifier as key
            >
              <p className="text-white text-base font-medium text-center">
                {item.title}
              </p>
            </Link>
          ))}
      </div>

      {categories.length > 0 && <div className="w-[80%] h-[1px] bg-gray-500" />}
    </div>
  );
};

export default Categories;
