import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { categoryquery, listingquery } from "../schemas/Queries";
import Categorylisting from "./Categorylisting";
import { fetchData } from "../api/requests";
import { Rings } from "react-loader-spinner";



const Listings = () => {
  const [ListingsCategories, setListingsCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const data = await fetchData(categoryquery);
        setListingsCategories(data.data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        // Add user feedback for failed data fetching
      }

      setLoading(false);
    };

    getData();
  }, []);

  return (
    <div className="flex-col gap-8 mt-[1rem] min-h-[200vh]  ">
      {loading ? (
       <div className="flex items-center justify-center">
        <Rings />
       </div>
      ) : (
        <div className="flex flex-col gap-8">
          {ListingsCategories.length > 0 &&
            ListingsCategories.map((item, i) => (
              <Categorylisting {...item} key={i} />
            ))}
        </div>
      )}
    </div>
  );
};

export default Listings;
