import React from 'react'
import { FaTelegramPlane } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Listing = ({app}) => {
  return (
    <Link
    className="bg-gray-700 rounded-lg my-3 md:mx-4  px-3 py-2 flex  gap-2"
    to={`/app/${app.id}`}
  >
    <div className="w-[100px] h-[100px]">
      <img
        src={app.thumbnailUrl}
        alt="app_image"
        className="w-full h-full object-cover rounded-lg"
      />
    </div>
    <div className="flex-1 flex flex-col justify-between">
      <div>
        <h3 className="text-white font-bold text-lg tracking-wide">
          Title :
        </h3>
        <p className="text-gray-400">{app.title}</p>
      </div>
      <Link
        onClick={(e) => e.stopPropagation()}
        to={`${app.listingurl}`}
      >
        <FaTelegramPlane fontSize={27} className="text-gray-400" />
      </Link>
    </div>
  </Link>
  )
}

export default Listing
