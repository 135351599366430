import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import CategoriesPage from "../pages/Categories";
import Applisting from "../pages/Applisting";
import AppDetailsPage from "../pages/AppDetailsPage";
import SearchPage from "../pages/Search";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Applisting />,
      },
      {
        path: "/search/:searchterm",
        element: <SearchPage />,
      },
      { path: "/categories/:id", element: <CategoriesPage /> },
      { path: "/app/:id", element: <AppDetailsPage /> },
    ],
  },
]);
