import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import Banner from "./components/Banner";
import Listings from "./components/Listings";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div className="w-full min-h-screen bg-main px-[2rem] py-[1rem] relative">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default App;
