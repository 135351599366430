import axios from "axios";

const baseUrl =
  "https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clu42w7cx0k7807up6pxmiexa/master";

export async function fetchData(schema) {
  try {
    const response = await axios.post(
      baseUrl,
      {
        query: schema,
      },
      {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
