import React from "react";
import Banner from "../components/Banner";
import Listings from "../components/Listings";

const Applisting = () => {
  return (
    <div className="mt-[3rem] flex flex-col ">
      <Banner />
      <Listings />
    </div>
  );
};

export default Applisting;
