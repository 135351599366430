import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getlisitngByCategoryId } from "../schemas/Queries";
import { fetchData } from "../api/requests";
import Listing from '../components/Listing'

const CategoriesPage = () => {
  const { id } = useParams();
  const [categoriesDetail, setCategoriesDetail] = useState({
    title: "",
    description: "",
  });

  const [listings, setlistings] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await fetchData(getlisitngByCategoryId(id));
      // console.log(response.data.listings[0].categories[0]);

      const cat = response.data.listings[0].categories[0];

      setCategoriesDetail((prev) => {
        return { ...cat };
      });

      setlistings(response.data.listings);
    };

    getData();

    console.log(categoriesDetail);
  }, [id]);
  return (
    <div>
      {/* Headers */}
      <div className="mt-[2rem] flex flex-col gap-2">
        <h1 className="text-white font-bold text-3xl">
          {categoriesDetail?.title}
        </h1>
        <p className="text-gray-400 md:w-[50%] text-lg">
          {categoriesDetail.description}
        </p>
      </div>
      {/* End Header */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {listings.map((item, i) => (
          <Listing app={item} key={i} />
        ))}
      </div>
      {/* Listings */}
      <div></div>
      {/* End Listings */}
    </div>
  );
};

export default CategoriesPage;
