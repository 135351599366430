import React, { useEffect, useState } from "react";
import { Rings } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { fetchData } from "../api/requests";
import { searchQuery } from "../schemas/Queries";
import Listing from "../components/Listing";

const SearchPage = () => {
  const { searchterm } = useParams();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await fetchData(
          searchQuery(
            `${searchterm.charAt(0).toUpperCase() + searchterm.slice(1)}`
          )
        );
        setListings(response.data.listings);
        console.log(response.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [searchterm]);

  return (
    <div className="mt-[2rem] w-full">
      {loading ? (
        <div className="w-full flex justify-center">
          <Rings />
        </div>
      ) : (
        <div className="">
          {/* ==== Headers ==== */}
          <div className="flex flex-col gap-2">
            <h1 className="font-bold text-white text-2xl tracking-wide">
              Search Results For
            </h1>
            <p className="text-gray-400 capitalize text-lg">{searchterm}</p>
          </div>
          {/* End Header */}
          {/* listing */}
          <div>
            {!listings.length > 0 ? (
              <div className="font-bold text-white text-2xl mx-auto">
                No Search Result for {searchterm}{" "}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {listings.map((item, i) => (
                  <Listing app={item} key={i} />
                ))}
              </div>
            )}
          </div>

          {/* end listings */}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
